<template>
  <div
    class="text-center d-block text-body-2 ma-2"
  >
    {{ $t("evalmee.home.student.footer.copyright", { date: new Date().getFullYear() }) }}
    <a
      :href="$t('evalmee.home.student.footer.privacyPolicy.link')"
      target="_blank"
    >
      {{ $t("evalmee.home.student.footer.privacyPolicy.label") }}
    </a>
  </div>
</template>
<script>
export default {
  name: "StudentHomeFooter",

}
</script>
