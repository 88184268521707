<template>
  <div ref="canvasContainer" class="canvas_container"></div>
</template>

<script>
import confetti from "canvas-confetti";

export default {
  name: "confetti",
  methods: {
    fire(target = this.$refs.canvasContainer) {
      let myCanvas = document.createElement("canvas")
      myCanvas.width = window.innerWidth
      myCanvas.height = window.innerHeight
      target.appendChild(myCanvas)

      let myConfetti = confetti.create(myCanvas, {
        resize: false,
        useWorker: true,
      })

      const duration = 7 * 1000
      const animationEnd = Date.now() + duration
      const defaults = { startVelocity: 30, spread: 360, ticks: 60, zIndex: 0 }

      function randomInRange(min, max) {
        return Math.random() * (max - min) + min
      }

      let interval = setInterval(function() {
        let timeLeft = animationEnd - Date.now()

        if (timeLeft <= 0) {
          return clearInterval(interval)
        }

        const particleCount = 30 * (timeLeft / duration)
        // since particles fall down, start a bit higher than random
        myConfetti(Object.assign({}, defaults, { particleCount, origin: { x: randomInRange(0.1, 0.3), y: Math.random() - 0.2 } }))
        myConfetti(Object.assign({}, defaults, { particleCount, origin: { x: randomInRange(0.7, 0.9), y: Math.random() - 0.2 } }))
      }, 250)
    },
  },
  mounted() {
    this.fire()
  },
}
</script>

<style scoped>
.canvas_container {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  pointer-events: none;
}

</style>
