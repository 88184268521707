<template>
  <v-item-group
    :value="step"
    class="text-center"
    mandatory
  >
    <v-item
      v-for="n in maxSteps"
      :key="`btn-${n}`"
      v-slot="{ active }"
    >
      <v-icon
        :color="active ? 'primary' : ((n - 1)> step ? 'grey lighten-2' : 'grey')"
        small
        class="transition-ease-in-out"
      >
        mdi-record
      </v-icon>
    </v-item>
  </v-item-group>
</template>

<script>
export default {
  name: "ProgressDots",
  props: {
    maxSteps: { type: Number, default: 0 },
    step: { type: Number, default: 0 },
  },
}
</script>

<style scoped >

</style>
